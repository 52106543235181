<template>
  <v-row class="mx-0 justify-center">
    <v-card v-if="loading" class="mt-md-10" width="650">
      <v-skeleton-loader width="650" type="card"> </v-skeleton-loader>
    </v-card>
    <v-card
      v-else-if="!registrationsInProgress && !registrationFinished"
      class="mt-md-10"
      width="650"
      :flat="$vuetify.breakpoint.smAndDown"
    >
      <v-card-title>
        {{ event.name }} - Willkommen zurück zur Anmeldung
      </v-card-title>
      <v-divider />
      <v-card-text>
        <event-info-list :event="event" />
        <v-btn @click="startRegistration" color="success" block>
          Anmeldung Fortsetzen
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card
      v-else-if="registrationsInProgress && !registrationFinished"
      width="1400"
      class="mt-md-10"
      :flat="$vuetify.breakpoint.smAndDown"
    >
      <v-card-title> {{ event.name }} - Anmeldung </v-card-title>
      <registration-stepper
        :event-id="eventId"
        :guest-registration="true"
      ></registration-stepper>
    </v-card>
    <v-card
      v-else
      class="mt-md-10"
      width="800"
      :flat="$vuetify.breakpoint.smAndDown"
    >
      <v-card-title>
        {{ event.name }} - Anmeldung von
        {{ user.forename + " " + user.surname }}
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="text-overline">
          Event Details:
        </div>
        <event-info-list v-if="event" :event="event" />
      </v-card-text>
      <v-divider />
      <v-card-text>
        <div class="text-overline">
          Eigene Angaben:
        </div>
        <div>
          <v-card-title>Stammdaten</v-card-title>
          <reduced-user-detail-list :user="user" />
          <emergency-contacts-manager-part :top-divider="false" />
          <registration-editor
            :event-id="event.id"
            :editable="false"
            :radio-button-group-row="false"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EventsAPI from "@/api/EventsAPI";
import RegistrationStepper from "@/components/registration/RegistrationStepper";
import EventInfoList from "@/components/events/EventInfoList";
import AccountAPI from "@/api/AccountAPI";
import RegistrationEditor from "@/components/registration/RegistrationEditor";
import ReducedUserDetailList from "@/components/user/ReducedUserDetailList";
import EmergencyContactsManagerPart from "@/components/personal/EmergencyContactsManagerPart";

export default {
  name: "PublicEventRegistration",
  components: {
    EmergencyContactsManagerPart,
    ReducedUserDetailList,
    RegistrationEditor,
    EventInfoList,
    "registration-stepper": RegistrationStepper
  },
  data() {
    return {
      loading: true,
      eventId: 0,
      registrationId: 0
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    registrationsInProgress() {
      return this.$store.state.events.registrationsInProgress
        .map(reg => reg.eventId)
        .includes(this.eventId);
    },
    registrationFinished() {
      return this.registrationId;
    },
    ...mapGetters("events", {
      eventGetter: "event",
      registrationGetter: "registration"
    }),
    event() {
      return this.eventGetter(this.eventId);
    },
    registration() {
      return this.registrationGetter(this.registrationId);
    },
    user() {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    ...mapActions("user", ["insertUserInfo"]),
    ...mapMutations("events", ["updateEvents", "updateRegistrations"]),
    ...mapActions("events", ["beginRegistration"]),
    ...mapMutations("user", ["setEventRegistrationToken"]),
    ...mapActions("snackbar", ["showSnackbar"]),
    async fetchEventByRegistrationToken() {
      return EventsAPI.getEventByRegistrationToken(this.token).then(event => {
        this.eventId = event.id;
        this.updateEvents([event]);
      });
    },
    async fetchRegistrationByToken() {
      return EventsAPI.getRegistrationByToken(this.token)
        .then(registration => {
          this.updateRegistrations([registration]);
          this.registrationId = registration.id;
        })
        .catch(() => {
          return;
        });
    },
    async fetchUserInformationByToken() {
      return AccountAPI.getUserInfoByRegistrationToken(this.token)
        .then(user => {
          this.insertUserInfo(user);
        })
        .catch(() => {
          return;
        });
    },
    startRegistration() {
      this.beginRegistration(this.eventId);
      this.registrationStarted = true;
    },
    onRegistrationFinished(id) {
      console.log(id);
      this.registrationId = id;
    }
  },
  async mounted() {
    this.setEventRegistrationToken(this.token);
    await Promise.all([
      this.fetchEventByRegistrationToken(),
      this.fetchRegistrationByToken(),
      this.fetchUserInformationByToken()
    ]);
    this.loading = false;
    this.$store.subscribe(mutation => {
      if (mutation.type === "events/finishRegistration") {
        this.registrationId = mutation.payload;
        this.showSnackbar({
          text: "Anmeldung Abgeschlossen",
          color: "success",
          timout: "10000"
        });
      }
    });
  }
};
</script>

<style scoped>
.v-stepper {
  box-shadow: initial;
  -webkit-box-shadow: initial;
}
</style>
