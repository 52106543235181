<template>
  <div class="mt-md-3 mx-md-8 mx-lg-16">
    <v-row class="justify-center mx-0">
      <v-card v-if="loading" class="mt-md-10" width="650">
        <v-skeleton-loader width="650" height="400" type="card">
        </v-skeleton-loader>
      </v-card>
      <v-card
        v-else
        class="mt-md-10"
        width="650"
        :flat="$vuetify.breakpoint.smAndDown"
      >
        <v-card-title> {{ event ? event.name : "" }} - Anmeldung </v-card-title>
        <v-divider />
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text>
              <event-info-list v-if="event" :event="event" />
              <div v-if="event.description !== '<p></p>'">
                <v-divider />
                <div class="pa-3" v-html="event.description" />
              </div>
              <v-tooltip top :disabled="!deadlineOver">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      :disabled="deadlineOver"
                      block
                      color="success"
                      @click="step = 2"
                    >
                      Anmelden
                    </v-btn>
                  </div>
                </template>
                <span>
                  Anmeldung nach der Anmeldefrist nicht mehr möglich.
                </span>
              </v-tooltip>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="2">
            <div class="pa-4">
              <div class="text-body-2">
                Um Dich für das Event {{ event.name }} anzumelden, hast du zwei
                Möglichkeiten. <br />
                1) Du erstellst einen Gast-Account, der ausschließlich für
                dieses Event benutzt wird oder <br />
                2) Du meldest dich mit deinem Scouttools-Account an (falls du
                einen hast)
              </div>
              <div class="mt-6">
                <div class="text-overline">
                  1) Gast-Account
                </div>
                <div class="ml-5 text-body-2">
                  Für den Gast-Account brauchen wir nur eine Email-Adresse von
                  Dir:
                </div>
                <v-form
                  ref="emailForm"
                  @submit.prevent="startNewGuestEventRegistration()"
                >
                  <v-row class="mx-0 mt-3 ml-md-10">
                    <v-text-field
                      v-model="email"
                      dense
                      label="Email"
                      outlined
                      style="max-width: 400px"
                      :rules="emailRules"
                      prepend-inner-icon="email"
                    />
                    <v-btn
                      color="success"
                      class="ml-3"
                      depressed
                      height="40"
                      @click="startNewGuestEventRegistration()"
                    >
                      Weiter
                    </v-btn>
                  </v-row>
                </v-form>
                <v-scale-transition>
                  <v-alert
                    type="success"
                    v-if="emailSent"
                    max-width="504"
                    class="ml-md-10"
                    dismissible
                  >
                    <div class="text-body-1">
                      Du erhälst in wenigen Minuten eine Email. <br />
                      Diese enthält deinen persönlichen Anmeldungslink.
                    </div>
                  </v-alert>
                </v-scale-transition>
              </div>
              <div class="mt-3 mb-6">
                <div class="text-overline">
                  2) Scouttools-Account
                </div>
                <div v-if="loggedIn" class="ml-5 ml-md-10 text-body-2">
                  Als
                  <b>{{ userInfo.forename }} {{ userInfo.surname }}</b>
                  anmelden:
                  <a
                    class="ml-1"
                    @click="
                      $router.push({
                        name: 'registration',
                        params: { eventId: event.id }
                      })
                    "
                    >&rarr; Zur Anmeldung</a
                  >
                </div>
                <a
                  v-else
                  class="ml-5 ml-md-10 text-body-1"
                  @click="$router.push('/lading')"
                  >&rarr; Zum Login</a
                >
              </div>
              <v-row class="mx-0 justify-end">
                <v-btn text @click="step = 1">
                  Zurück
                </v-btn>
              </v-row>
            </div>
          </v-window-item>
        </v-window>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import EventsAPI from "@/api/EventsAPI";
import EventInfoList from "@/components/events/EventInfoList";
import { mapGetters, mapState } from "vuex";

export default {
  name: "PublicEventView",
  components: { EventInfoList },
  data() {
    return {
      loading: true,
      event: undefined,
      email: "",
      emailRules: [
        v => !!v || "Bitte gebe eine Email-Adresse ein",
        v => /.+@.+\..+/.test(v) || "Bitte gültige Email-Adresse eingeben"
      ],
      step: 1,
      emailSent: false
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    deadlineOver() {
      return (
        new Date(this.event.registrationInformation.registrationDeadline) -
          Date.now() <
        0
      );
    },
    ...mapState("user", ["userInfo"]),
    ...mapGetters("user", ["loggedIn"])
  },
  methods: {
    async getEventByToken() {
      return EventsAPI.getEventByToken(this.token).then(event => {
        this.event = event;
      });
    },
    startNewGuestEventRegistration() {
      this.emailSent = false;
      if (!this.$refs.emailForm.validate()) return;
      EventsAPI.startNewGuestRegistration(this.email, this.token)
        .then(() => {
          this.emailSent = true;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  async mounted() {
    await this.getEventByToken();
    this.loading = false;
  }
};
</script>

<style scoped></style>
